<template>
  <div>
    <HomePageTop/>
    <div style="margin-top: -32px">
      <header>
        <div>FBA直送サービス</div>
      </header>
      <footer>
        <div class="footerContainer">
          <div
              style="position: relative;margin-bottom: 192px;width: 1400px;display: flex;align-items: center;flex-direction: column">
            <img src="../../assets/homePage/FBADirectDeliveryService/amazonIcon.png" class="amazonIcon" alt="">
            <div class="fontSize24 lineHeight32" style="text-align: center;margin-top: -150px">
              フルフィルメント by Amazon（FBA）は、Amazonの世界屈指の配送システムを活用し、<br>
              商品の保管から注文処理・出荷・配送・返品に関するカスタマーサービスまでを提供するだけでなく、<br>
              Amazon.co.jpでの販売力を強化し、売上拡大を支援するサービスです。<br>
            </div>
          </div>
          <div style="line-height: 36px;font-size: 24px;margin-bottom: 60px;width: 100%;text-align: center">
            当社はお客様の需要に応じ、アマゾン倉庫への直送が可能です。<br>
            現在では快速船便と航空便とも配送できます。
          </div>
          <div class="footerText">詳細の利用料金は <span
              class="colorB4272B">国際送料</span>
            と<span class="colorB4272B">オプション料金</span> で確認ください。
          </div>
          <div class="footerImageList">
            <img src="../../assets/homePage/FBADirectDeliveryService/18.png" alt="">
            <img src="../../assets/homePage/FBADirectDeliveryService/19.png" alt="">
            <img src="../../assets/homePage/FBADirectDeliveryService/20.png" alt="">
            <img src="../../assets/homePage/FBADirectDeliveryService/21.png" alt="">
            <img src="../../assets/homePage/FBADirectDeliveryService/22.png" alt="">
            <img src="../../assets/homePage/FBADirectDeliveryService/23.png" alt="">
          </div>
        </div>
      </footer>
    </div>
    <FootVue/>
  </div>
</template>

<script>
import FootVue from "../../components/foot/Foot.vue";
import HomePageTop from "../../components/head/HomePageTop.vue";

export default {
  components: {
    FootVue,
    HomePageTop
  },
}
</script>

<style lang="scss" scoped>
header {
  background: url("../../assets/homePage/FBADirectDeliveryService/banner.png") no-repeat;
  width: 100%;
  height: 350px;
  background-size: 100% 100%;
  padding-top: 153px;
  text-align: center;

  div {
    text-shadow: 0 0 27px rgba(0, 0, 0, 0.4);
    color: #FFFFFF;
    font-weight: bold;
    font-size: 48px;
    letter-spacing: 15px;
  }
}

.fontSize24 {
  font-size: 24px;
}

.line-height36 {
  line-height: 36px;
}

.amazonIcon {
  //position: absolute;
  //left: 35%;
  //top: -50%;
  width: 438px;
}

footer {
  background: #fff;
  width: 100%;
  padding: 80px 0;

  .footerText {
    display: flex;
    align-items: center;
    font-size: 24px;
    justify-content: center;
    margin-bottom: 100px;

    span {
      display: inline-block;
      margin: 0 6px;
    }
  }

  .footerContainer {
    width: 1400px;
    margin: 0 auto;

    .footerImageList {
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: space-between;
    }
  }
}
</style>
